@import url('../../components/common/forms/form.css');

.close-tower-card {
  @apply rounded-lg leading-6 shadow-light dark:shadow-dark p-4 h-72;
}
.close-tower-card p {
  @apply font-bold;
}
.close-tower-card span {
  @apply font-medium;
}
